
import Swiper from 'https://unpkg.com/swiper/swiper-bundle.esm.browser.min.js'

//// configure Swiper to use modules
//SwiperCore.use([Navigation, Pagination]);

$(function () {
    var mySwiper = undefined;

    function initSwiper() {
        var screenWidth = $(window).width();

        if (screenWidth > 550 && mySwiper == undefined) {
            if ($('.swiper-wrapper-js').hasClass("row")) {
                $('.swiper-wrapper-js').removeClass("row justify-content-center");
                $('.swiper-wrapper-js').addClass("swiper-wrapper");
            }

            if ($('.swiper-item-js').hasClass("col-sm-12")) {
                $('.swiper-item-js').removeClass("col-sm-12 col-md-6 col-lg-3");
                $('.swiper-item-js').addClass("swiper-slider");
                $('.swiper-pagination').removeClass("d-none");
            }

            enableSwiper();
            
        } else if (screenWidth < 550) {
            $('.swiper-wrapper-js').removeClass("swiper-wrapper");
            $('.swiper-wrapper-js').addClass("row justify-content-center");

            $('.swiper-item-js').removeClass("swiper-slider");
            $('.swiper-item-js').addClass("col-sm-12 col-md-6 col-lg-3");

            $('.swiper-pagination').addClass("d-none");

            mySwiper = undefined;
        }
    }

    function enableSwiper() {
        mySwiper = new Swiper('.swiperRelatedProducts', {
            loop: true,
            slidesPerView: 4,
            spaceBetween: 30,
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    centeredSlidesBounds: true
                },
                767: {
                    slidesPerView: 2
                },
                991: {
                    slidesPerView: 4,
                }
            },
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

        });

    };

    initSwiper();

    //Swiper plugin initialization on window resize
    $(window).on('resize', function () {
        initSwiper();
    });

    new Swiper('.swiperProducts', {
        loop: true,
        slidesPerView: 4,
        spaceBetween: 30,
        breakpoints: {
            0: {
                slidesPerView: 1,
                centeredSlidesBounds: true
            },
            767: {
                slidesPerView: 2
            },
            991: {
                slidesPerView: 4,
            }
        },

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

    });



    new Swiper('.swiperCultures', {
        loop: true,
        slidesPerView: 4,
        spaceBetween: 30,
        breakpoints: {
            0: {
                slidesPerView: 1,
                centeredSlidesBounds: true
            },
            767: {
                slidesPerView: 2
            },
            991: {
                slidesPerView: 4,
            }
        },

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

    });
});